var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-list"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.name)+" "),_c('a-button',{attrs:{"type":"primary","shape":"round","icon":"plus"},on:{"click":function($event){return _vm.$router.push(_vm.$paths.productsAdd)}}},[_vm._v(" 添加商品 ")])],1),_c('a-divider'),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"商品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name__contains']),expression:"['name__contains']"}],staticClass:"form-field",attrs:{"placeholder":"请输入商品名称"},on:{"blur":_vm.onFilterChange,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilterChange($event)}}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":"商品ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id']),expression:"['id']"}],staticClass:"form-field",attrs:{"placeholder":"请输入商品ID"},on:{"blur":_vm.onFilterChange,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilterChange($event)}}})],1),_c('div',{staticClass:"actions"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onFilterChange}},[_vm._v(" 确认筛选 ")]),_c('a-button',{on:{"click":_vm.onFilterReset}},[_vm._v(" 清空条件 ")])],1)],1)],1),_c('div',{staticClass:"content"},[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"pagination":{
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: function (total) { return ("总计 " + total + " 条"); },
        current: Number(_vm.query.page_number),
        pageSize: Number(_vm.query.page_size),
        total: Number(_vm.count)
      },"rowKey":function (record) { return record.product.id; },"size":"middle"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"image",fn:function(text, record){return [_c('a-avatar',{staticClass:"image",attrs:{"src":text,"shape":"square","size":55,"icon":"video-camera"},on:{"click":function($event){return _vm.$router.push(_vm.$paths.products + '/' + record.product.id)}}})]}},{key:"info",fn:function(text, record){return _c('div',{staticClass:"info",on:{"click":function($event){return _vm.$router.push(_vm.$paths.products + '/' + record.product.id)}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(record.product.name))]),_c('div',{staticClass:"slogan"},[_vm._v(_vm._s(record.product.slogan))]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(record.product.description)}})])}},{key:"status",fn:function(text, record){return [_c('a-switch',{attrs:{"checked":text === 'launch'},on:{"change":function($event){return _vm.onStatusChange(record)}}})]}},{key:"top",fn:function(text, record){return [_c('a-switch',{attrs:{"checked":text},on:{"change":function($event){return _vm.onTopChange(record)}}})]}},{key:"hidden",fn:function(text, record){return [_c('a-switch',{attrs:{"checked":text},on:{"change":function($event){return _vm.onHiddenChange(record)}}})]}},{key:"time",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("timeFormat")(text))+" ")]}},{key:"priority",fn:function(text, record){return [_c('a-input-number',{attrs:{"defaultValue":text,"precision":0},on:{"pressEnter":function($event){return _vm.onPriorityChange(record, $event.target.value)}}})]}},{key:"actions",fn:function(text, record){return [(_vm.isTest)?_c('a-button',{attrs:{"icon":"sync"},on:{"click":function($event){return _vm.onSync(record)}}}):_vm._e(),_vm._v(" "),_c('a-button',{attrs:{"icon":"delete","type":"danger","disabled":record.product.status === 'launch'},on:{"click":function($event){return _vm.onDelete(record)}}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }