





















































































































import Vue from "vue";
import { mapState } from "vuex";
import { ProductStage, ProductStatus } from "@/enum";
import { dispatch, ListQuery, Product, RootState } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
      columns: [
        {
          title: "商品",
          key: "main_medias",
          dataIndex: "product.main_medias[0].url",
          scopedSlots: { customRender: "image" },
          width: "100px",
          align: "center"
        },
        {
          key: "name",
          dataIndex: "product.name",
          scopedSlots: { customRender: "info" }
        },
        {
          title: "阶段",
          key: "stage",
          dataIndex: "product.stage",
          customRender: (text: string) => (ProductStage as any)[text]
        },
        {
          title: "创建时间",
          key: "create_time",
          dataIndex: "product.create_time",
          scopedSlots: { customRender: "time" },
          sorter: true
        },
        {
          title: "更新时间",
          key: "update_time",
          dataIndex: "product.update_time",
          scopedSlots: { customRender: "time" },
          sorter: true
        },
        {
          title: "上架",
          key: "status",
          dataIndex: "product.status",
          scopedSlots: { customRender: "status" },
          sorter: true,
          sortDirections: ["descend"],
          align: "center"
        },
        {
          title: "置顶",
          key: "is_top",
          dataIndex: "product.is_top",
          scopedSlots: { customRender: "top" },
          sortDirections: ["ascend"],
          sorter: true,
          align: "center"
        },
        {
          title: "隐藏",
          key: "is_hidden",
          dataIndex: "product.is_hidden",
          scopedSlots: { customRender: "hidden" },
          sortDirections: ["ascend"],
          sorter: true,
          align: "center"
        },
        {
          title: "排序权重",
          key: "priority",
          dataIndex: "product.priority",
          scopedSlots: { customRender: "priority" },
          sorter: true
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "100px",
          align: "center"
        }
      ],
      ProductStatus: ProductStatus
    };
  },
  computed: mapState({
    isTest(state: RootState) {
      return state.app.isTest;
    },
    list(state: RootState) {
      return state.products.list;
    },
    query(state: RootState) {
      return state.products.query;
    },
    count(state: RootState) {
      return state.products.count;
    }
  }),
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .productsGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering,
        status: (filters.status && filters.status[0]) || this.query.status
      });
    },
    onStatusChange(product: Product) {
      product.product.status =
        product.product.status === "launch" ? "pending" : "launch";
      dispatch.productsPatch(product).then(() => {
        this.getList(this.query);
      });
    },
    onTopChange(product: Product) {
      product.product.is_top = !product.product.is_top;
      dispatch.productsPatch(product).then(() => {
        this.getList(this.query);
      });
    },
    onHiddenChange(product: Product) {
      product.product.is_hidden = !product.product.is_hidden;
      dispatch.productsPatch(product).then(() => {
        this.getList(this.query);
      });
    },
    onPriorityChange(product: Product, value: string) {
      product.product.priority = Number(value);
      dispatch.productsPatch(product).then(() => {
        this.getList(this.query);
      });
    },
    onSync(product: Product) {
      this.$confirm({
        title: `确认同步商品“${product.product.name}”到万里牛？`,
        okText: "同步",
        onOk: () => {
          dispatch.productsSync([product.product.id]).then(() => {
            this.$message.success("商品已同步");
          });
        }
      });
    },
    onDelete(product: Product) {
      this.$confirm({
        title: `确认删除商品“${product.product.name}”的所有信息？`,
        okText: "删除",
        onOk: () => {
          dispatch.productsDelete(product).then(() => {
            this.$message.success("商品已删除");
            if (this.list.length === 1) {
              this.getList({
                ...this.query,
                page_number: this.query.page_number - 1 || 1
              });
            } else {
              this.getList(this.query);
            }
          });
        }
      });
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
